/**
 *  @param {string} json - JSON a reconstruir
 *  @param {json} data - JSON con los datos a reconstruir
 *  @return {json} - JSON reconstruido
 */

/**
  Forma del json a reconstruir:
  [
  {
    "nombre": "MATILDE / VALLE VERDE / E. MATILDE",
    "numero": "RA-01 A",
    "coordenadas_ida" : [
      {
        "latitud": 20.033889, 
        "longitud": -98.812778,
        "direccion": "EL MIRADOR/MATILDE ",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible",
        "fotos_referencia": [
          "https://cdn.hidalgo.gob.mx/tuzobus/imagenes/alimentadoras/RA1A-1.JPG",
          "https://cdn.hidalgo.gob.mx/tuzobus/imagenes/alimentadoras/RA1A-2.JPG"
        ]
      },
      {
        "latitud": 20.033889,  
        "longitud": -98.812778,
        "direccion": "Av Lindero, Matilde, 42119 Acayuca, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"        
      },
      {
        "latitud": 20.031944, 
        "longitud": -98.812778,
        "direccion": "12 de Diciembre sn Colonia, El Mirador, 43845 El Mirador, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.029722, 
        "longitud": -98.812778,
        "direccion": "43845 El Mirador, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.029167,  
        "longitud": -98.812778,
        "direccion": "Juvencio Meneses, 43845 El Mirador, Hgo",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.029722, 
        "longitud": -98.811111,
        "direccion": "Juvencio Meneses, 43845 El Mirador, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
        
      },
      {
        "latitud": 20.030556,
        "longitud": -98.808611,
        "direccion": "43830 Av lindero Acayuca, 43830 Pachuca de Soto, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"        
      },
      {
        "latitud": 20.031111,  
        "longitud": -98.807500,
        "direccion": "43845 El Mirador, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.033056,  
        "longitud": -98.806667,
        "direccion": "Av. Reforma Agraria 511-517, Matilde, 42119 Pachuca de Soto, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.032222, 
        "longitud": -98.805278,
        "direccion": "Av. Reforma Agraria 458-360, Matilde, 42119 Pachuca de Soto, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.030556, 
        "longitud": -98.803056,
        "direccion": "Matilde, 42119 Pachuca de Soto, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      }
    ],
    "coordenadas_vuelta" : [
      {
        "latitud": 20.030556,  
        "longitud": -98.803056,
        "direccion": "Matilde, 42119 Pachuca de Soto, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.032222,  
        "longitud": -98.805278,
        "direccion": "Av. Reforma Agraria 458-360, Matilde, 42119 Pachuca de Soto, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.033056,  
        "longitud": -98.806667,
        "direccion": "Av. Reforma Agraria 511-517, Matilde, 42119 Pachuca de Soto, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.031111, 
        "longitud": -98.807500 ,
        "direccion": "43845 El Mirador, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.030278,  
        "longitud": -98.808889,
        "direccion": "Bugambilias, San Antonio, 43845 Acayuca",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.029722,  
        "longitud": -98.811111,
        "direccion": "Juvencio Meneses, 43845 El Mirador, Hgo",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.030000, 
        "longitud": -98.812778,
        "direccion": "25 de Agosto, San Antonio, 43845 Acayuca, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.032222,  
        "longitud": -98.813056,
        "direccion": "20 de Noviembre, San Antonio, 43845 Acayuca, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.032222, 
        "longitud": -98.813056,
        "direccion": "20 de Noviembre, San Antonio, 43845 Acayuca, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      },
      {
        "latitud": 20.032222, 
        "longitud": -98.813056,
        "direccion": "20 de Noviembre, San Antonio, 43845 Acayuca, Hgo.",
        "horario": "Lunes a Viernes de 05:30 a 22:30 hrs, Sabados de 06:00 a 22:00 hrs y Domingo de 7:00 a 22:00 hrs",
        "estatus": "Activo",
        "descripcion":"Disponible"
      }
    ]
  },
  ...]
 */

export const reconstruirJSON = (json) => {
  const newJSON = []
  json.forEach((element) => {
    const coordenadasIda = element.coordenadas_ida.map((coordenada) => {
      return {
        horario: coordenada.horario,
        numero: element.numero,
        nombre: element.nombre,
        direccion: coordenada.direccion,
        type: 'alimentadora',
        fotos_referencia: coordenada.fotos_referencia,
        coordenadas: {
          latitud: coordenada.latitud,
          longitud: coordenada.longitud,
        },
      };
    }
    );

    const coordenadasVuelta = element.coordenadas_vuelta.map((coordenada) => {
      return {
        horario: coordenada.horario,
        numero: element.numero,
        nombre: element.nombre,
        direccion: coordenada.direccion,
        type: 'alimentadora',
        fotos_referencia: coordenada.fotos_referencia,
        coordenadas: {
          latitud: coordenada.latitud,
          longitud: coordenada.longitud,
        },
      };
    });

    newJSON.push(...coordenadasIda, ...coordenadasVuelta);
  });

  return newJSON;
};




